<style scoped>
.vertical-align-center {
  min-height: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  background-color: #eceeef;
  border-radius: 5px;
  font-size: 24px;
}
</style>
<template>
  <b-container class="vertical-align-center">
    <p class="text p-5">{{ $t("home.no-role") }}</p>
  </b-container>
</template>

<script>
export default {};
</script>
